
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => (
  <div className="main_error">
    <h1 className="main_error-title">Page Not Found</h1>
    <p className="main_error-message">The requested file or page cannot be found at this address.</p>
    <p className="main_error-message">We are sorry for the inconvenience.</p>
    <Link className="main_error-link" to="/">Go Home</Link>
  </div>
);

export default NotFound;