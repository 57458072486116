import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Button from "../elements/Button";
import FilterCollection from "./partials/filters/FilterCollection";
import ScrollUp from "../elements/ScrollUp";
import ProductGallery from "./partials/ProductGallery";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const sectionHeader = {
  title: "Products",
  paragraph: "A product guide.",
};

const renderHeader = (
  <SectionHeader
    data={sectionHeader}
    className="center-content reveal-from-down mt-32 mb-16"
  />
);

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const ProductGuide = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "products section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    className
  );

  const innerClasses = classNames(
    "products-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const [Filtered, setFilters] = useState({
    category: [],
    brand: [],
    finish: [],
    hold: [],
    hair: [],
  });

  const [Products, setProducts] = useState([]);
  const [IgnoreData, setIgnoreData] = useState(0);
  const [BoundData] = useState(4);
  const [OnPageSize, setOnPageSize] = useState();
  const [loading, setLoading] = useState(false);
  const [productAmount, setProductAmount] = useState();

  useEffect(() => {
    // initialise the page with all products
    // the skip and limit are acknowledged with every render (sent to mongodb)
    const data = {
      skip: IgnoreData,
      limit: BoundData,
    };
    getProducts(data);
  }, []);

  const getProducts = async (config) => {
    // console.log(config.filters)
    try {
      setLoading(true);
      const res = await fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/api/products`,
        {
          method: "POST",
          body: JSON.stringify(config),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!res.ok) {
        throw new Error("Failed to fetch product");
      }

      const prodData = await res.json();
      // if config loadmore is set to true (loadmore btn is clicked), append the incoming products here

      setProductAmount(prodData.count);

      if (config.loadMore) {
        setProducts([...Products, ...prodData.prods]);
      } else {
        setProducts(prodData.prods);
      }

      setOnPageSize(prodData.onPgeSize);
    } catch (err) {
      console.log("connection to product database failed " + err);
    } finally {
      setLoading(false);
    }
  };

  const revealResults = (filters) => {
    const variables = { skip: 0, limit: BoundData, filters: filters };

    getProducts(variables);
    // should not ignore products (skip in mongodb) when purely filtered
    setIgnoreData(0);
  };
  const handleFilters = (filters, filterType) => {
    //this was used before, impacting checkboxfilter memoisation.
    //const newFilter = {...Filtered}
    Filtered[filterType] = filters;
    // send the filtered items to server/mongodb with 'filters' key for access.
    revealResults(Filtered);
    // useState is updated here.
    setFilters(Filtered);
  };

  const onLoadMore = () => {
    const skip = IgnoreData + BoundData;

    const variables = {
      skip: skip,
      limit: BoundData,
      loadMore: true,
      filters: Filtered,
    };

    getProducts(variables);
    setIgnoreData(skip);
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {renderHeader}
          {/* using memo for both FilterCollection and ProductGallery. This reduced unecessary renders. */}
          <FilterCollection Filtered={Filtered} handleFilters={handleFilters} />

          <div className="container prod-gallery">
            {productAmount ? (
              <p className="text-xxs">
                {productAmount} {productAmount > 1 ? "products" : "product"}{" "}
                found
              </p>
            ) : (
              ""
            )}

            <Spin
              indicator={antIcon}
              spinning={loading}
              delay={500}
              tip={"Fetching Products"}
            />

            <ProductGallery Products={Products} />
          </div>
          {OnPageSize >= BoundData && (
            <div className="center-content mt-32">
              <Button onClick={onLoadMore} color="dark">
                Load More
              </Button>
            </div>
          )}
        </div>
        <ScrollUp destination="product-top" />
      </div>
    </section>
  );
};

ProductGuide.propTypes = propTypes;
ProductGuide.defaultProps = defaultProps;

export default ProductGuide;
