
import AlertModal from '../AlertModalContent';

export const Success = ({resData, onClose}) => {
    return(
        <AlertModal className="successAlert" headColour="success" labelHead="Success!"
        paraColour="low" 
        button labelButton="Ok" btnOneClick= {() => onClose()}>
        {/* {resData.clientname}, see you at our {' '} 
        <strong>{resData.location}</strong> {' '}venue {' '} 
        on {' '}
        <strong>{resData.dateBooked}</strong> {' '}
         */}
         Sit tight {' '} 
         {resData.data.createEvent.name}, 
         {' '} Our team at {' '} 
         {resData.data.createEvent.location} 
         {' '} will review your form and a response will be sent to {' '} 
         {resData.data.createEvent.email} {' '}
        <span role="img" aria-label="smile">😀</span><br/><br/>
        <span role="img" aria-label="warn">⚠️</span>If a response is not sent within 24 hours, please check your spam/junk folder.
      </AlertModal>
    );
}


export const EmailError = ({onClose}) => {

  return(
    <AlertModal className="emailErrAlert" headColour="error" labelHead="Error!"
    paraColour="low" 
    button labelButton="Ok" btnOneClick= {() => onClose()}>
    We are having a difficulties connecting to the email host. Please try again.
    <span role="img" aria-label="sad">😔</span><br/><br/> 
  </AlertModal>
  );
}

export const DBError = ({onClose}) => {

  return(
    <AlertModal className="dbErrAlert" headColour="error" labelHead="Database Error! (request limit)"
    paraColour="low" 
    button labelButton="Ok" btnOneClick= {() => onClose()}>
    We are having a difficulties connecting to the database host due internal issues or 
    request limitation. Please try again.
    <span role="img" aria-label="sad">😔</span><br/><br/> 
  </AlertModal>
  );
}