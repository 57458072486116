import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import ClientReview from '../components/sections/ClientReview';
import Bookings from '../components/sections/Bookings';
import Gallery from '../components/sections/Gallery';
import TradingHours from '../components/sections/TradingHours';

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles topDivider/>
      <FeaturesSplit topDivider bottomDivider imageFill className="illustration-section-02" />
      <Gallery />
      <ClientReview topDivider bottomDivider/>
      <TradingHours/>
      <Bookings bottomDivider/>
    </>
  );
}

export default Home;