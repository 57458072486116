export const services = ["Adult Haircut", "Kids Haircut", "Beard"];

export const locations = [
  {
    label: "Balwyn",
    value: "Balwyn",
  },
  {
    label: "Camberwell",
    value: "Camberwell",
  },
];

export const phoneNumbers = [
  {
    name: "Balwyn",
    number: "(03) 9859 5019",
  },
  {
    name: "Camberwell",
    number: "(03) 9943 6259",
  },
];

export const mapLinks = {
  Balwyn:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1874.4734286633009!2d145.0815767585088!3d-37.802318843587756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad64165bddb3b65%3A0x1b4594f508028a58!2sGents%20Cave%20Barber%20Balwyn!5e0!3m2!1sen!2sau!4v1599804649390!5m2!1sen!2sau",
  Camberwell:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3150.432516259831!2d145.09261511531986!3d-37.850169079745726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6404d2004afcf%3A0x41af132beffe01f3!2sGents%20Cave%20Barber%20Camberwell!5e0!3m2!1sen!2sau!4v1605163027488!5m2!1sen!2sau",
};

export const locationInfo = [
  {
    name: "Balwyn",
    latitude: -37.8032972486511,
    longitude: 145.0830226433147,
    shareGMLink: "https://goo.gl/maps/rTEwmr6if3RP43jL9",
    // shopImg: "https://lh5.googleusercontent.com/p/AF1QipOlOgTUc77eq8bJPLjCMtXUTRFOSgRrq3zE-qE0=w203-h114-k-no"
  },
  {
    name: "Camberwell",
    latitude: -37.85015953293418,
    longitude: 145.09480245729642,
    shareGMLink: "https://g.page/gentscavebarbermelb?share",
    // shopImg: "https://lh5.googleusercontent.com/p/AF1QipNSkRi0W2_O0ZB-tPYXObcFTtJP8zRwpTMyqByL=w203-h152-k-no"
  },
];

export const openings = [
  {
    dayName: "Sunday",
    from: "10am",
    to: "4pm",
  },
  {
    dayName: "Monday",
    from: "9am",
    to: "6pm",
  },
  {
    dayName: "Tuesday",
    from: "9am",
    to: "6pm",
  },
  {
    dayName: "Wednesday",
    from: "9am",
    to: "6pm",
  },
  {
    dayName: "Thursday",
    from: "9am",
    to: "7pm",
  },
  {
    dayName: "Friday",
    from: "9am",
    to: "7pm",
  },
  {
    dayName: "Saturday",
    from: "9am",
    to: "5pm",
  },
];

export const serviceInfo = {
  clipTitle: "Clipper Cut",
  clipDesc:
    "Any cut to your taste followed by a shave with straight razor, skin toner and shoulder massage.",
  clipPrice: "$20 - $40",
  shaveTitle: "Shaving",
  shaveDesc:
    "For premium result, at our barbershop we combine modern technics with traditional ones.",
  shavePrice: "$30",
  mustTitle: "Mustache Treat",
  mustDesc:
    "Looking to try something new with your facial hair? Our barbers will nicely trim your mustache.",
  mustPrice: "$10",
  beardTitle: "Beard Trimming",
  beardDesc:
    "Keep looking your best with regular beard trimming. Just stop by for a beard procedure!",
  beardPrice: "$10",
};

export const reviewLinks = [
  {
    name: "Balwyn",
    link: "http://search.google.com/local/writereview?placeid=ChIJZTvbvWVB1moRWIoCCPWURRs",
  },
  {
    name: "Camberwell",
    link: "http://search.google.com/local/writereview?placeid=ChIJz68EIE1A1moR8wH-7ysTr0E",
  },
];

export const email = [
  {
    link: "gentscavebarberbalwyn@gmail.com",
  },
];
