import React from 'react';
// import sections
import ProductGuide from '../components/sections/ProductGuide'


const Products = () => {

  return (
    <>
        <ProductGuide bottomDivider/>
    </>
  );
}

export default Products;