import React, {useState} from 'react'
import { Checkbox} from 'antd';
import PropTypes from 'prop-types';

const propTypes = {
    list: PropTypes.array,
    handleFilters: PropTypes.func
} 

const defaultProps = {
    list: [],
    handleFilters: undefined
}


// use memo was once used in this component. evry time the lists are updating, it would upd component
// however, this resets the original filter stored in ProductGuide.js. 
// const areListEqual = (prevProps, nextProps) => {
//     return prevProps.list === nextProps.list;
// }

// cause: new object for Filtered was created, hence losing prevstate data. The filtered array 
// is used consistenly in productguide.js
const areListEqual = (prevProps, nextProps) => {
    return prevProps.list === nextProps.list;
}
const CheckboxFilter = ({
    list,
    handleFilters
}) => {


    const [Checked, setChecked] = useState([])

    const handleChecked = (id) =>{
        // console.log(Checked.indexOf(id) === -1);
        return Checked.indexOf(id) !== -1;
    }

    const handleChange = (value) => {

        const currentIndex = Checked.indexOf(value);
        const newChecked = [...Checked];

        // everytime the state of the checkbox is changed, 
        //if curr index returns -1, no match found in the checked state. Hence push it (new check). 
        // else uncheck it by removing from array.  
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        // console.log(Checked);
        setChecked(newChecked);
        handleFilters(newChecked);
        
    }

    const resets= ()=>{
        setChecked([]);
        handleFilters([]); 
    }
    return (
        <>

    {list && list.map((categVal, i) => {
        return (
        <React.Fragment key={i}>
            <Checkbox
                onChange={() => {handleChange(categVal.id)}}
                checked={handleChecked(categVal.id)}>
                {categVal.name}
            </Checkbox>

        </React.Fragment>
        )
        }
        )
    }{Checked.length > 0 && <div className="reset-container"><a className="anchor-reset text-xxs" onClick={resets}>Clear</a></div>}
        </>
    )
}


CheckboxFilter.propTypes = propTypes;
CheckboxFilter.defaultProps = defaultProps;

export default React.memo(CheckboxFilter, areListEqual);
