import React from "react";
// import sections

import ContactMap from "../components/sections/ContactMap";
import ContactMethods from "../components/sections/ContactMethods";

const Contact = () => {
  return (
    <>
      <ContactMethods bottomDivider />
      <ContactMap bottomDivider />
    </>
  );
};

export default Contact;
