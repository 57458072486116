import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import { reviewLinks, email } from "../../logicStore/BusinessRules";
import SocialSVG from "../elements/SocialSVG";

const propTypes = {
  children: PropTypes.node,
  ...SectionTilesProps.types,
};

const defaultProps = {
  children: null,
  ...SectionTilesProps.defaults,
};

const reviewContent = (
  <ul className="list-reset contact-reviews">
    {" "}
    {reviewLinks.map((r, i) => {
      return (
        <React.Fragment key={i}>
          <li>
            <a href={r.link} target="_blank" rel="noreferrer noopener">
              {r.name}
            </a>
          </li>
        </React.Fragment>
      );
    })}
  </ul>
);

const ContactMethods = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "contact-method section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    className
  );

  const innerClasses = classNames(
    "contact-method-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Contact Us",
    paragraph: "",
  };

  const details = [
    {
      data: {
        title: "Got Questions?",
        paragraph: "Ping us an email",
      },
      content: <a href={"mailto:" + email[0].link}>Click here to Email us</a>,
    },
    {
      data: {
        title: "Leave a Review",
        paragraph: "We appreciate a review",
      },
      content: reviewContent,
    },
    {
      data: {
        title: "Social Media",
        paragraph: "Get the latest updates on our social media platforms",
      },
      content: (
        <ul className="list-reset contact-social">
          <li>
            <SocialSVG fb width={30} height={30} />
          </li>
          <li>
            <SocialSVG inst width={30} height={30} />
          </li>
        </ul>
      ),
    },
  ];

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content reveal-from-down mt-32 mb-16"
          />
          <div className={tilesClasses}>
            {details.map((d, i) => {
              return (
                <div className="tiles-item" key={i}>
                  <div
                    className="tiles-item-inner reveal-from-bottom"
                    data-reveal-delay={200 * i}
                  >
                    <div className="features-tiles-item-header">
                      <SectionHeader data={d.data} tag="h3" />
                      <div className="features-tiles-item-content text-xs">
                        {d.content}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

ContactMethods.propTypes = propTypes;
ContactMethods.defaultProps = defaultProps;

export default ContactMethods;
