import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Image from '../../elements/Image';



const propTypes = {
    Products: PropTypes.array
}

const defaultProps = {
    Products: []
}

let render = 1;
const areProdsEqual = (prevProps, nextProps) => {
    return prevProps.Products === nextProps.Products;
}

const ProductGallery = ({
    Products,
    pushLeft
}
) => {

    const tilesClasses = classNames(
        'tiles-wrap',
        pushLeft && 'push-left'
      );


      return (

        <div className={tilesClasses} >
            {Products.length == 0  && <div className="no-show center-content text-xs ">No products to show</div>}
            {
            Products.map(
                (p,i) => { return (
                  
                    <div className="tiles-item" key={i}>
                        <div className="tiles-item-inner center-content">
                        <Image 
                            src={p.image}
                            // altsrc={p.webp_image}
                            width={"350px"}
                            height={"300px"}/>
                        <div className="products-item-content">
                        {p.name} <br/>
                        { p.fragrance ? <p className="text-xxs mt-16">
                        Smells Like: {p.fragrance}</p> : ''}
                        <p className="text-xs mt-4">{p.description}</p>
                        </div>
                        </div>
                    </div>
                        )
                    }
                )
            }
        </div>

      )

}

ProductGallery.propTypes = propTypes;
ProductGallery.defaultProps = defaultProps;

export default React.memo(ProductGallery, areProdsEqual);