import React from "react";
import classNames from "classnames";
import { phoneNumbers } from "../../../logicStore/BusinessRules";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-contact", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset center-content">
        {phoneNumbers.map((n, i) => {
          return (
            <React.Fragment key={i}>
              <li>
                {n.name}
                <a href={"tel:" + n.number}>{n.number}</a>
              </li>
            </React.Fragment>
          );
        })}
      </ul>
    </nav>
  );
};

export default FooterNav;
