import React from "react";
import SectionHeader from "../components/sections/partials/SectionHeader";
// import sections

const AboutUs = () => {
  const sectionHeader = {
    title: "About Us",
    paragraph: "",
  };
  return (
    <>
      <SectionHeader
        data={sectionHeader}
        className="center-content reveal-from-down mt-32 mb-16"
      />
      <div className="container-xs center-content text-sm">
        Gents Cave Barber Shop is proud to serve Balwyn and Camberwell with a
        curated experience for each individual’s hair and beard needs. We are
        committed to providing you a quality service in a friendly and
        comfortable environment.
      </div>
    </>
  );
};

export default AboutUs;
