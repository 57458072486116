import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
        <Link to="/">
          <Image 
            src={require('./../../../assets/images/logo.png').default}
            // altsrc={require('./../../../assets/images/logo.webp').default}
            alt="GCB Logo"
            width={'100vw'}
            height={'100vh'}
            style={{position: 'relative', top: '15px'}}/>
        </Link>
        
    </div>
  );
}

export default Logo;