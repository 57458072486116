import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {getDayName} from '../../../../logicStore/DateReadability';

const propTypes = {
  children: PropTypes.node,
  labelHead: PropTypes.string,
  phoneNo: PropTypes.string,
  direction: PropTypes.string,
  openings: PropTypes.array
}

const defaultProps = {
  children: null,
  labelHead: '',
  phoneNo: '',
  direction: '',
  openings: undefined
  
}

const AlertModalContent = ({
    children,
    labelHead,
    phoneNo,
    direction,
    openings,
    className,
    ...props
}) => {

  const classes = classNames(
    'container center-content',
    className
  );
  const headClasses = classNames(
      'h4 mt-0 mb-2'
  )
  const textClasses = classNames(
    'p mt-0 mb-0'
  )


  return (
    <>
      <div className={classes}>
        {labelHead && <h4 className={headClasses} id={props.id}>{labelHead}</h4>}
        <p className={textClasses}>
            Phone number: <a href={'tel: '+ phoneNo}>{phoneNo}</a><br/><br/>
            Open today: {openings.filter((o) => o.dayName == [getDayName])[0].from} - {openings.filter((o) => o.dayName == [getDayName])[0].to}<br/><br/>
        <a href={direction} target="_blank" rel="noreferrer noopener">Click here for directions</a><br/>
        
        </p>

      </div>

    </>
  );
}

AlertModalContent.propTypes = propTypes;
AlertModalContent.defaultProps = defaultProps;

export default AlertModalContent;