import moment from 'moment';

import {openings} from './BusinessRules';

export const outputDateTime = (datetime) => {
    // console.log(date + ' ' + time)
    return datetime ? moment(datetime).format('dddd MMM Do hh:mm a') : null
}

export const getDayName = moment(new Date(), "YYYY-MM-DD HH:mm:ss").format('dddd')

export const getInputDateFormat = moment().format("YYYY-MM-DD")

export const get24TimeFormat = (datetime, currentFormat) => {
    return datetime ? moment(datetime, currentFormat).format("HHmm") : null
}

export const getDayNameFromUser = (datetime)=> {
    return datetime ? moment(datetime).format('dddd') : 'Monday'
}

export const validateUserTime = (value) => {
    // console.log(value)
      const userInput = get24TimeFormat(value, '')
      const validInitialTime = get24TimeFormat(openings.filter((o) => o.dayName == [getDayNameFromUser(value)])[0].from, "hha") 
      const validEndTime = get24TimeFormat(openings.filter((o) => o.dayName == [getDayNameFromUser(value)])[0].to, "hha")
  
  
      if(userInput <= validEndTime && userInput >= validInitialTime){
        // console.log('valid')
        return true
      }
      else{
        // console.log('invalid')
        return false
      }
  }