import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    fb: PropTypes.bool,
    ggle: PropTypes.bool,
    inst: PropTypes.bool
        
}

const defaultProps = {
  fb: false,
  ggle: false,
  inst: false,
  width: undefined,
  height: undefined,
}

const SMLinks ={ 
    fbLink: "https://www.facebook.com/TheManCaveBarberShopCamberwell/",
    instLink: "https://www.instagram.com/gentscavebarbermelb/?hl=en",
    ggLink: "https://www.google.com/maps/d/edit?mid=1Wo09gvsDbjE31G94BmeAhVKxjZ5u-TNL&usp=sharing"
}

const SocialSVG = ({
  className,
  fb,
  ggle,
  inst,
  width,
  height,
  ...props
}) => {


  return(
    <React.Fragment>
      {fb && 
      <a href={SMLinks.fbLink} target="_blank" rel="noreferrer noopener">
            <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg">
            <title>Facebook</title>
            <path
            d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z" />
        </svg>
      </a>
      }

      {inst && 
      <a  href={SMLinks.instLink} target="_blank" rel="noreferrer noopener">
          <svg
                width={width}
                height={height}
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg">
                <title>Instagram</title>
                <g>
                <circle cx="12.145" cy="3.892" r="1" />
                <path
                    d="M8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
                <path
                    d="M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z" />
                </g>
            </svg>
        </a>
        }


  
    </React.Fragment>
  );
}



SocialSVG.propTypes = propTypes;
SocialSVG.defaultProps = defaultProps;

export default SocialSVG;