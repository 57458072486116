import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../../elements/Button';
import ButtonGroup from '../../elements/ButtonGroup';

const propTypes = {
  children: PropTypes.node,
  headColour: PropTypes.string,
  labelHead: PropTypes.string,
  paraColour: PropTypes.string,
  textUpperCase: PropTypes.bool,
  button: PropTypes.bool,
  labelButton: PropTypes.string,
  secondBtn: PropTypes.bool,
  secondLabelBtn: PropTypes.string, 
  btnOneClick: PropTypes.func, 
  btnTwoClick: PropTypes.func
}

const defaultProps = {
  children: null,
  headColour: '',
  paraColour: '',
  labelHead:'',
  textUpperCase: false,
  button: false,
  labelButton: '',
  secondBtn: false,
  secondLabelBtn: '',
}

const AlertModalContent = ({
    children,
    headColour,
    labelHead,
    paraColour,
    className,
    textUpperCase,
    button,
    labelButton,
    secondBtn,
    secondLabelBtn, 
    btnOneClick,
    btnTwoClick,
    ...props
}) => {

  const classes = classNames(
    'container',
    className
  );
  const headClasses = classNames(
      'mt-0 mb-16',
      headColour && `text-color-${headColour}`
  )

  const paraClasses = classNames(
    paraColour && `text-color-${paraColour}`,
    textUpperCase && 'tt-u'
  )

  return (
      <>
   <div className={classes}>

    {labelHead && <h1 className={headClasses} id={props.id}>{labelHead}</h1>}
    <p className={paraClasses}>
        {children}
    </p>
    {button && <ButtonGroup>

        <Button onClick={btnOneClick} 
        color="dark">{labelButton}</Button>

        {secondBtn && <Button color="primary" 
        onClick={btnTwoClick}>{secondLabelBtn}</Button>}

    </ButtonGroup>}
    </div>
    </>
  );
}

AlertModalContent.propTypes = propTypes;
AlertModalContent.defaultProps = defaultProps;

export default AlertModalContent;