import {React} from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import {openings} from '../../logicStore/BusinessRules';
import {getDayName} from '../../logicStore/DateReadability'


const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const TradingHours = ({
  className,
  topOuterDivider,
  bottomOuterDivider, 
  topDivider,
  bottomDivider,
  hasBgColor,
  pushLeft,
  ...props
}) => {

    const outerClasses = classNames(
        'trading section ',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        className
    );

    const innerClasses = classNames(
        'trading-inner section-inner ',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
        'tiles-wrap ',
        pushLeft && 'push-left'
    );

    const sectionHeader = {
        title: 'Trading Hours',
        paragraph: "Open 7 days/week"
    };



  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-xs" id="trading">
        <div className={innerClasses}>
        <SectionHeader data={sectionHeader} className="center-content reveal-from-top" data-reveal-delay="150"/>
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner trading-item">
                        <table className="trade-table">
                          <tbody>
                            {
                            openings.map(
                                (open, i) => {
                                
                                return <tr key={i} className={getDayName == open.dayName ? 'highlight-day' : ''}>
                                          <th>{open.dayName}</th><td>{open.from} - {open.to}</td></tr>
                                
                              }
                            )
                            }
                            </tbody>
                        </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

TradingHours.propTypes = propTypes;
TradingHours.defaultProps = defaultProps;

export default TradingHours;