import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";
import Button from "../elements/Button";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import FormLabel from "../elements/FormLabel";
import ButtonGroup from "../elements/ButtonGroup";
import Checkbox from "../elements/Checkbox";
import SectionHeader from "./partials/SectionHeader";
import Select from "../elements/Select";
import FormHint from "../elements/FormHint";
import { services, locations, openings } from "../../logicStore/BusinessRules";
import SmoothScroll from "../elements/SmoothScroll";
import {
  Success,
  EmailError,
  DBError,
} from "./partials/feedbackViews/FeedbackType";
import {
  getDayName,
  validateUserTime,
  outputDateTime,
} from "../../logicStore/DateReadability";
import IsolateMapRender from "./partials/formIsolateReRender/IsolateMapRender";
import IsolateDateTimeOutput from "./partials/formIsolateReRender/IsolateDateTimeOutput";
import DateTimePicker from "react-datetime-picker";
// import 'react-datetime-picker/dist/DateTimePicker.css';

// for looping through classnames (useEffect - disable keyboard input)
const dateType = ["year", "month", "day"];

// sectionProps may contain many elements, hence (...). SectionProps is imported.
// typical property types (such topOuterDivider) are accessed through the 'types' property.
// this is used to type check all elements in the component.
const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

// instansiates all the default types for a typical section and in addition instansiates 'split' property
const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

// all the applicable properies are passed into Bookings component (section)
// and validated via the scss properties. with the '&&'. (e.g false and true => false)
const Bookings = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  split,
  ...props
}) => {
  // outerClasses are the styles if applied (given true in home.js)
  const outerClasses = classNames(
    "bookings section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    className
  );

  const innerClasses = classNames(
    "bookings-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "bookings-split"
  );

  const sectionHeader = {
    title: "Make a Booking",
    paragraph: "Fill the following form to make a booking",
  };

  // manages registeration of client details, and convey relevant errors using hook forms
  // making this a controlled component, since value is passed into inputs. Hence in sync
  // "Controlled components are NOT bad. In fact, they are the most recommended."
  const {
    register,
    handleSubmit,
    errors,
    reset,
    control,
    formState: { isSubmitSuccessful },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      location: "",
      name: "",
      email: "",
      tel: "",
      datetime: "",
      services: "",
      message: "",
    },
  });

  // console.log('submit count:', submitCount, '\n', 'errors: ', errors, '\n','is Validating: ',
  //             isValidating, '\n', 'dirty fields: ', dirtyFields);

  const sendToDB = async (values) => {
    try {
      const requestBody = {
        query: `
        mutation {
          createEvent(eventInput: {location: "${values.location}", 
                                name: "${values.name}",
                                email: "${values.email}",
                                mobile: "${values.tel}",
                                dateBooked: "${outputDateTime(
                                  values.datetime
                                )}",
                                services: "${values.services}",
                                comment: "${values.message}"
                                })
                                {
                                  _id
                                  location
                                  name
                                  email
                                  mobile
                                  dateBooked
                                  services
                                  comment
                                  createdAt
                                  updatedAt
                                }
       } 
      `,
      };

      setLoading(true);

      const res = await fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/api/graphql`,
        {
          method: "POST",
          body: JSON.stringify(requestBody),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!res.ok) {
        throw new Error("Failed to connect to server");
      }
      const resData = await res.json();
      // console.log(resData)
      //email sent to worker to confirm/reject
      sendEmail(resData);

      // console.log(resData.data.createEvent);
      setLoading(false);

      // show success modal to user
      confirmAlert({
        customUI: ({ onClose }) => {
          return <Success resData={resData} onClose={onClose} />;
        },
        closeOnEscape: true,
        closeOnClickOutside: false,
      });
    } catch (err) {
      setLoading(false);
      confirmAlert({
        customUI: ({ onClose }) => {
          return <DBError onClose={onClose} />;
        },
        closeOnEscape: true,
        closeOnClickOutside: false,
      });
    }
  };

  const sendEmail = async (resJson) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_PUBLIC_URL}/api/mailer`,
        {
          method: "POST",
          body: JSON.stringify(resJson),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!res.ok) {
        throw new Error("Failed to connect to email host");
      }

      setLoading(false);
    } catch (error) {
      console.log("Error with email api " + error);
      setLoading(false);
      confirmAlert({
        customUI: ({ onClose }) => {
          return <EmailError onClose={onClose} />;
        },
        closeOnEscape: true,
        closeOnClickOutside: false,
      });
    }
  };
  // when component initially mounts, this attribute is applied. ([])
  useEffect(() => {
    dateType.map((d) =>
      document
        .getElementsByClassName(`react-datetime-picker__inputGroup__${d}`)[0]
        .setAttribute("readOnly", "readonly")
    );
  }, []);

  // this will be applied when (isSubmitSuccessfull and reset) are alterd.
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmit = (values, e) => {
    // 'e' is a synthetic event. This apart of the react event system.
    // preventDefault prevents from submitting the form at this stage.
    e.preventDefault();
    sendToDB(values);
  };

  const [isLoading, setLoading] = useState(false);

  return (
    <section {...props} className={outerClasses}>
      <div className="container" id="bookings">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content mt-32"
          />
          <div className="container-xs">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="bookings-loc mb-8 ">
                <Controller
                  name="location"
                  control={control}
                  rules={{ required: true }}
                  render={({ onChange, value, ref }) => (
                    <Select
                      label="Select a Location"
                      size="sm"
                      id="shopLoc"
                      name="location"
                      ref={ref}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      value={value}
                      style={{ borderColor: errors.location && "red" }}
                    >
                      <option
                        key={0}
                        disabled
                        value=""
                        className="text-color-mid"
                      >
                        Please Select
                      </option>
                      {locations.map((loc, i) => {
                        return (
                          <option key={i + 1} value={loc.value}>
                            {loc.label}
                          </option>
                        );
                      })}
                    </Select>
                  )}
                />
                <div className="map-container reveal-scale-up" delay={1000}>
                  {!isSubmitSuccessful && (
                    <IsolateMapRender control={control} />
                  )}
                </div>
              </div>

              <div className="bookings-name mb-8">
                <Input
                  label="Name"
                  id="clientName"
                  size="sm"
                  type="text"
                  name="name"
                  ref={register({ required: true })}
                  style={{ borderColor: errors.name && "red" }}
                  placeholder="Name"
                />
              </div>

              <div className="bookings-email mb-8">
                <Input
                  label="Email"
                  id="clientEmail"
                  size="sm"
                  type="email"
                  name="email"
                  hint="A response will be sent to you. Please enter an accessible email address."
                  status="mid"
                  placeholder="Email"
                  ref={register({ required: true })}
                  style={{ borderColor: errors.email && "red" }}
                />
              </div>

              <div className="bookings-phone mb-8">
                <Input
                  label="Mobile Number"
                  id="clientPhone"
                  size="sm"
                  type="tel"
                  name="tel"
                  ref={register({
                    required: true,
                    pattern:
                      /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$/,
                  })}
                  style={{ borderColor: errors.tel && "red" }}
                  placeholder="Mobile Number"
                />
              </div>

              {/* {console.log(get24TimeFormat(openings.filter((o) => o.dayName == [getDayNameFromUser(getValues('datetime'))])[0].from, "hha") === get24TimeFormat(getValues('datetime'), ''))} */}

              {/* {console.log('user day name: ', getDayNameFromUser(getValues('datetime')))} */}

              <div className="bookings-date mt-16 mb-16">
                <FormLabel>Booking Date and Time</FormLabel>

                <FormHint status="mid" className="ta-c mt-8 mb-32">
                  Today's opening hours are: <br />
                  <p className="text-color-warning mb-0">
                    {openings.filter((o) => o.dayName == [getDayName])[0].from}{" "}
                    - {openings.filter((o) => o.dayName == [getDayName])[0].to}
                  </p>
                  <SmoothScroll to="trading" duration={1500}>
                    Click Here
                  </SmoothScroll>{" "}
                  to see other days
                </FormHint>

                <div style={{ position: "relative" }}>
                  {errors.datetime && (
                    <FormHint
                      status="warning"
                      className="datetime-error mt-0 mb-0"
                    >
                      {" "}
                      Enter a valid time for the selected day.
                    </FormHint>
                  )}
                </div>
                <Controller
                  name="datetime"
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) => validateUserTime(value),
                  }}
                  render={({ onChange, value, onFocus, onBlur, ref }) => (
                    <DateTimePicker
                      name="datetime"
                      onChange={onChange}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      yearPlaceholder={"yyyy"}
                      monthPlaceholder={"mm"}
                      dayPlaceholder={"dd"}
                      hourPlaceholder={"hh"}
                      minutePlaceholder={"mm"}
                      amPmAriaLabel={"Select AM/PM"}
                      nativeInputAriaLabel={"Date and Time"}
                      value={value}
                      disableClock={true}
                      format={"dd/MM/yyyy hh:mm a"}
                      minDate={new Date()}
                      className={
                        errors.datetime
                          ? "react-datetime-picker--highlight"
                          : "react-datetime-picker--border"
                      }
                    />
                  )}
                />
              </div>

              <div className="bookings-type mb-8">
                <FormLabel>Type of Service(s): </FormLabel>
                <br />
                {/* this maps all the items in services and creates the Checkbox. The checked items are placed in an array (services) */}

                {services.map((c, i) => {
                  return (
                    <Checkbox
                      key={i}
                      className="mr-32"
                      type="checkbox"
                      value={c}
                      name="services"
                      id={c}
                      ref={register}
                    >
                      {c}
                    </Checkbox>
                  );
                })}
              </div>

              <div className="bookings-message mb-32">
                <Input
                  label="Message"
                  id="message"
                  type="textarea"
                  size="sm"
                  name="message"
                  placeholder="Type a Message Here"
                  ref={register}
                />
              </div>

              {!isSubmitSuccessful && (
                <IsolateDateTimeOutput control={control} />
              )}

              {/* mutate, send the details to mongodb.  */}
              <ButtonGroup className="mt-32">
                <Button
                  loading={isLoading}
                  disabled={isLoading}
                  wide
                  color="dark"
                  size="sm-w-form--padding-h"
                >
                  Submit
                </Button>
              </ButtonGroup>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

Bookings.propTypes = propTypes;
Bookings.defaultProps = defaultProps;

export default Bookings;
