import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import GoogleMapReact from "google-map-react";
import Marker from "./partials/contactMapUtils/MapMarker";
import SectionHeader from "./partials/SectionHeader";
import {
  locationInfo,
  phoneNumbers,
  openings,
} from "../../logicStore/BusinessRules";
import Modal from "../elements/Modal";
import MarkerModalText from "./partials/contactMapUtils/MarkerModalText";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const ContactMap = ({
  className,
  topOuterDivider,
  topDivider,
  bottomDivider,
  bottomOuterDivider,
  hasBgColor,
  ...props
}) => {
  const outerClasses = classNames(
    "contact-map section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    className
  );

  const innerClasses = classNames(
    "contact-map-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Our Locations",
    paragraph: "Click on a location marker to see more",
  };

  const getMapOptions = () => {
    return {
      disableDefaultUI: true,
      mapTypeControl: false,
      streetViewControl: false,
      styles: require(`../../assets/json/map-styles.json`),
    };
  };
  const [center] = useState({ lat: -37.7865748, lng: 145.1387617 });
  const [zoom] = useState(11);
  const [MapModalActive, setMapModalactive] = useState(false);
  const [Text, setModalText] = useState("");

  const openModal = (info, i) => {
    setMapModalactive(true);
    // console.log(Object.values(openings)[i].hours)
    // console.log(info)

    setModalText(
      <MarkerModalText
        labelHead={info.name}
        phoneNo={Object.values(phoneNumbers)[i].number}
        direction={info.shareGMLink}
        openings={openings}
        img={info.shopImg}
      />
    );
  };

  const closeModal = () => {
    setMapModalactive(false);
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="google-map reveal-scale-up" data-reveal-delay="700">
            <SectionHeader
              data={sectionHeader}
              tag="h3"
              className="center-content"
            />
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_MAPS_API,
              }}
              defaultCenter={center}
              defaultZoom={zoom}
              options={getMapOptions}
            >
              {locationInfo.map((loc, i) => {
                return (
                  <Marker
                    key={i}
                    lat={loc.latitude}
                    lng={loc.longitude}
                    tooltip={loc.name}
                    onMarkerClick={() => openModal(loc, i)}
                  />
                );
              })}
            </GoogleMapReact>
          </div>
          <Modal
            id="marker-modal"
            show={MapModalActive}
            handleClose={closeModal}
            children={Text}
          />
        </div>
      </div>
    </section>
  );
};

ContactMap.propTypes = propTypes;
ContactMap.defaultProps = defaultProps;

export default ContactMap;
