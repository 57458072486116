export const CategoryFilter = [
    {id : 1, name: 'Hair Styling'},
    {id : 2, name: 'Beard'}
];

export const BrandFilter = [
    {id : 1, name: 'Uppercut'},
    {id: 2, name: 'Muk'},
];

export const FinishFilter = [
    {id: 1, name: 'Natural Shine (Matte)'},
    {id: 2, name: 'Low Shine'},
    {id: 3, name: 'Medium Shine'},
    {id: 4, name: 'High shine'}
]

export const HoldFilter = [
    {id: 1, name: 'Flexible'},
    {id: 2, name: 'Light'},
    {id: 3, name: 'Medium'},
    {id: 4, name: 'Firm'},
    {id: 5, name: 'Strong'},
    {id: 6, name: 'Heavy'}
]


export const HairTypeFilter = [
    {id: 1, name: 'Short length'},
    {id: 2, name: 'Medium length'},
    {id: 3, name: 'Thin/Fine'},
    {id: 4, name: 'Wavy/Curly'},
    {id: 5, name: 'Straight'},
    {id: 6, name: 'All Hair types/length'},
    {id: 7, name: 'Hard to control hair'}
]
