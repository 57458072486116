import React from 'react';
import classNames from 'classnames';
import SocialSVG from '../../elements/SocialSVG';

const FooterSocial = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-social',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
            <SocialSVG fb width={16} height={16}/>
        </li>
        <li>
          <SocialSVG inst width={16} height={16}/>
        </li>
      </ul>
    </div>
  );
}

export default FooterSocial;