import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { HashLink } from "react-router-hash-link";
import { NavLink, useLocation } from "react-router-dom";

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideBookNow: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideBookNow: false,
  bottomOuterDivider: false,
  topOuterDivider: false,
  topDivider: false,
  bottomDivider: false,
};

const Header = ({
  className,
  navPosition,
  hideNav,
  hideBookNow,
  bottomOuterDivider,
  topOuterDivider,
  topDivider,
  bottomDivider,
  ...props
}) => {
  const [isActive, setIsactive] = useState(false);

  const nav = useRef(null);
  const hamburger = useRef(null);
  const location = useLocation();

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener("keydown", keyPress);
    document.addEventListener("click", clickOutside);
    return () => {
      document.removeEventListener("keydown", keyPress);
      document.addEventListener("click", clickOutside);
      closeMenu();
    };
  });

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    setIsactive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  };

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  };

  const clickOutside = (e) => {
    if (!nav.current) return;
    if (
      !isActive ||
      nav.current.contains(e.target) ||
      e.target === hamburger.current
    )
      return;
    closeMenu();
  };

  const classes = classNames(
    "site-header",
    bottomOuterDivider && "has-bottom-divider",
    topOuterDivider && "has-top-divider",

    className
  );

  return (
    <header {...props} className={classes}>
      <div className="container">
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider",
            topDivider && "has-top-divider"
          )}
        >
          {!hideNav && (
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames("header-nav", isActive && "is-active")}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="header-nav-inner">
                  <ul
                    className={classNames(
                      "list-reset h6",
                      navPosition && `header-nav-${navPosition}`
                    )}
                  >
                   
                    <li>
                      <NavLink
                        to="/"
                        exact
                        activeStyle={{ color: "#cca3a3" }}
                        className="link-anchor"
                        onClick={closeMenu}
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/products"
                        exact
                        activeStyle={{ color: "#cca3a3" }}
                        className="link-anchor"
                        onClick={closeMenu}
                      >
                        Products
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/aboutus"
                        exact
                        activeStyle={{ color: "#cca3a3" }}
                        className="link-anchor"
                        onClick={closeMenu}
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/contact"
                        exact
                        activeStyle={{ color: "#cca3a3" }}
                        className="link-anchor"
                        onClick={closeMenu}
                      >
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                  {!hideBookNow && (
                    <ul className="list-reset header-nav-right">
                      <li>
                        <HashLink
                          to="/#bookings"
                          className="button button-nav button-wide-mobile"
                          onClick={closeMenu}
                        >
                          Book Now
                        </HashLink>
                      </li>
                    </ul>
                  )}
                </div>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
