import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SmoothScroll from '../elements/SmoothScroll'


const propTypes = {
  destination: PropTypes.string.isRequired,
}


const ScrollTopArrow = React.forwardRef(({
  className,
  destination
}, ref) =>

{
  
  const footer = document.querySelector('.site-footer');
  const scrollSelector = document.querySelector('.scrollTop');
    
    const [revealScroll, setRevealScroll] = useState(false)

    const [fixedScroll, setFixedScroll] = useState(true)
    
      useEffect(()=>{
        window.addEventListener('scroll', checkFixedScroll)
        return function cleanup() {
          window.removeEventListener('scroll', checkFixedScroll)
        }
      })
  
    useEffect(()=>{
      window.addEventListener('scroll', checkScrollTop)
      return function cleanup() {
        window.removeEventListener('scroll', checkScrollTop)
      }
    })
    
    const checkFixedScroll = () => {
      try {
        if ((scrollSelector.getBoundingClientRect().top + window.pageYOffset) +
          scrollSelector.offsetHeight >= footer.getBoundingClientRect().top){
          setFixedScroll(false)
        }
        if(window.pageYOffset + window.innerHeight < (footer.getBoundingClientRect().top + window.pageYOffset)) {

          setFixedScroll(true);
        }
      }
      catch(err){
        console.log(err)
      }
    }

    const checkScrollTop = () => {
      if (!revealScroll && window.pageYOffset > 450){
        setRevealScroll(true);
      } else if (revealScroll && window.pageYOffset <= 450){
        setRevealScroll(false);
      }
    };
    
    return (
    
        <SmoothScroll to={destination} className='scrollUp-container'><div style={{display: revealScroll ? 'flex' : 'none', position: fixedScroll ? 'fixed': 'absolute'}} className="scrollTop"></div></SmoothScroll>
    );
  })
  
  export default ScrollTopArrow;