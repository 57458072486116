import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';

const propTypes = {
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  bottomOuterDivider: false,
  bottomDivider: false
}

const Header = ({
  className,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {

  const classes = classNames(
    'site-top-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );

  return (
    <header
      {...props}
      className={classes}
    >
      <div className="container-xs">
        <div className={
          classNames(
            'site-top-header-inner',
            bottomDivider && 'has-bottom-divider'
          )}>
          <Logo />
          
        </div>
      </div>
    </header>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;