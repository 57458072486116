import React from 'react';

const Marker = (props) => {


    const { color, tooltip, onMarkerClick } = props;
    return (
      <div className="marker-barber" 
      style={{ backgroundColor: color, cursor: 'pointer'}}
      onClick={onMarkerClick}
      >
        <span className="marker-barber-text"><span className="text-bg">{tooltip}</span></span>
        </div>
    );
  };

  export default Marker;