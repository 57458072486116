import React, {memo} from 'react'
import FormHint from '../../../elements/FormHint'
import { useWatch}  from 'react-hook-form';
import { mapLinks} from '../../../../logicStore/BusinessRules';


function areEqual(prevProps, nextProps) {

    return prevProps.control.useWatchFieldsRef === nextProps.control.useWatchFieldsRef;
    // the component will be updated only on `contact` props changes.
}

const IsolateMapRender = ({ control }) =>{

    const location = useWatch({
      control,
      name: 'location', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
      defaultValue: '' // default value before the render
  });

return(

    <React.Fragment>

      <FormHint status="mid" className="ta-c mb-8">{location ? "Map of Selected Location" : "Select a location to view map"}</FormHint>
      <iframe name="map" title="shopMap" className={location ? 'form-map' : 'form-map map-placeholder'} id="maplocation"
        src={mapLinks[location]}/>

  </React.Fragment>
  
  );
    
}

export default memo(IsolateMapRender, areEqual);