import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Deck from './partials/Deck';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Gallery = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'gallery section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    className
  );

  const innerClasses = classNames(
    'gallery-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: 'Gallery Deck',
    paragraph: "To view the images, flick to the right or left"
  };
  
  return (
    <section
      {...props}
      className={outerClasses}
    >
         <div className={innerClasses}>
      <SectionHeader data={sectionHeader} className="center-content reveal-from-top"/>
        <div className="container-deck ">
            <Deck />
           
        </div>
      </div>
    </section>
  );
}

Gallery.propTypes = propTypes;
Gallery.defaultProps = defaultProps;

export default Gallery;