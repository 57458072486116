Object.defineProperty(exports, "__esModule", {
    value: !0
});
var _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function(a) {
        return typeof a
    } : function(a) {
        return a && "function" == typeof Symbol && a.constructor === Symbol && a !== Symbol.prototype ? "symbol" : typeof a
    },
    support = void 0,
    isWebpSupported = exports.isWebpSupported = function() {
        if ("undefined" != typeof support) return support;
        var a = "object" === ("undefined" == typeof document ? "undefined" : _typeof(document)) ? document.createElement("canvas") : {};
        return support = 0 === a.toDataURL("image/webp").indexOf("data:image/webp"), support
    };