import React, {memo} from 'react'
import { useWatch}  from 'react-hook-form';
import moment from 'moment';
import FormHint from '../../../elements/FormHint'
import {outputDateTime} from '../../../../logicStore/DateReadability';

function areEqual(prevProps, nextProps) {
    
    return prevProps.control.useWatchFieldsRef === nextProps.control.useWatchFieldsRef;
    // the component will be updated only on `contact` props changes.
}

const IsolateDateTimeOutput = ({ control }) =>{

  const datetime = useWatch({
    control,
    name: 'datetime', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  });


  return(
    <React.Fragment>

      {datetime &&
      <>
        <div className="bookings-confirm-text mb-12 mt-12">
        {moment(datetime).isValid() && <FormHint className="ta-c">Your selected booking Date/Time is: <br/>
        
        <p className="text-color-success mb-0 text-sm">{outputDateTime(datetime)}</p> 
        
        </FormHint>}
        </div>
      </>      
      }

  </React.Fragment>
  
  );
    
}

export default memo(IsolateDateTimeOutput, areEqual);