import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
// import ImageDistort from "react-image-list-distort";

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const content = [
  {
    tag: "Facial",
    title: "Beard Shaving", 
    desc: "For premium result, at our barbershop we combine modern technics with traditional ones",
    BUimgLoc: require('./../../assets/images/feature-image02.jpg'),
    imgLoc: require('./../../assets/images/feature-image02.webp'),
    textReveal: "reveal-from-left",
    imgreveal: "reveal-from-right"
  },
  {
    tag: "Haircut",
    title: "Kids Haircut", 
    desc: "Kids are welcome",
    textReveal: "reveal-from-right",
    BUimgLoc: require('./../../assets/images/feature-image01.jpg'), 
    imgLoc: require('./../../assets/images/feature-image01.webp'), 
    imgreveal: "reveal-from-left"
  },
  {
    tag: "Service",
    title: "Uncontested Service", 
    desc: "Our vision is to create a relaxing and friendly atmosphere where you can find a perfect service that goes with you.",
    textReveal: "reveal-from-left",
    BUimgLoc: require('./../../assets/images/feature-image03.jpg'), 
    imgLoc: require('./../../assets/images/feature-image03.webp'), 
    imgreveal: "reveal-from-right"
  }
]


const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'What We Offer',
    paragraph: 'A wide range of barbering services'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>


          {content.map((c, i) => {
            return(
            <div className="split-item" key={i}>
              <div className={`split-item-content center-content-mobile ${c.textReveal}`} data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  {c.tag}
                  </div>
                <h3 className="mt-0 mb-12">
                  {c.title}
                  </h3>
                <p className="m-0">
                  {c.desc}
                  </p>
              </div>
              <div className={
                classNames(
                  `split-item-image center-content-mobile ${c.imgreveal}`,
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={c.BUimgLoc.default}
                  // altsrc={c.imgLoc.default}
                  alt={`Features split ${i}`}
                  width={528}
                  height={396} />
              </div>
            </div>
            );
          })}

          </div>
        </div>
      </div>
     
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
