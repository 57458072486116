import React, {useEffect} from 'react'
import CheckboxFilter from './../../partials/filters/CheckboxFilter'
import { Collapse } from 'antd';
import {CategoryFilter,
        BrandFilter,
        FinishFilter,
        HoldFilter,
        HairTypeFilter} from '../../../../logicStore/FilterRules'

import PropTypes from 'prop-types';

import 'antd/lib/spin/style/index.css';
import 'antd/lib/checkbox/style/index.css';

let render = 1;

const filterInfo = [
    {
      header:'Category', 
      list: CategoryFilter,
      filterName: 'category'
    },
    {
      header:'Brand', 
      list: BrandFilter,
      filterName: 'brand' 
    },
    {
      header:'Finish', 
      list: FinishFilter,
      filterName: 'finish' 
    },
    {
      header:'Hold', 
      list: HoldFilter,
      filterName: 'hold' 
    },
    {
      header:'Hair Type', 
      list: HairTypeFilter,
      filterName: 'hair' 
    }
]

const propTypes = {
    Filtered: PropTypes.object,
    handleFilters: PropTypes.func
} 

const defaultProps = {
    Filtered: {},
    handleFilters: undefined
}

const FilterCollection = ({
    Filtered,
    handleFilters
}) => {
    const { Panel } = Collapse;
    return (

        <div id="product-top" className="container filter-outer reveal-scale-up">
            <div className="filter-inner">
                <ul className='list-reset'>  
                {
                    filterInfo.map((f, i)=> {
                        return (
                            <li key={i}>
                            <Collapse  className={Filtered[f.filterName].length > 0 ? "highlight-border" :'' }>
                                <Panel  header={f.header} key={f.filterName}>
                                <CheckboxFilter list={f.list} handleFilters={filters => handleFilters(filters, f.filterName)}/>
                                </Panel>
                            </Collapse>
                            </li>
                            )
                    })
                }
                </ul>
            </div>

        </div>


    )
}

FilterCollection.propTypes = propTypes;
FilterCollection.defaultProps = defaultProps; 

export default FilterCollection;