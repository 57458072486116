import Rating from "@material-ui/lab/Rating";
import classNames from "classnames";
import React from "react";
import { balwyn, camberwell } from "../../logicStore/GoogleReviews";
import { SectionTilesProps } from "../../utils/SectionProps";
import Image from "../elements/Image";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const reviewerID = {
  balAuthor: Math.floor(Math.random() * 5),
  cambAuthor: Math.floor(Math.random() * 5),
  rosAuthor: Math.floor(Math.random() * 5),
};

const ClientReview = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "client-review section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    className
  );

  const innerClasses = classNames(
    "client-review-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "Customer Reviews",
    paragraph: "Featured reviews from our clients",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content reveal-from-right"
          />
          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-right"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="client-review-item-content">
                  <Image
                    className="profile-img"
                    src={
                      balwyn.map((r) => r.profile_photo_url)[
                        reviewerID.balAuthor
                      ]
                    }
                    width={"50px"}
                    height={"50px"}
                  />
                  <p className="text-sm mb-0 mt-32">
                    {balwyn.map((r) => r.text)[reviewerID.balAuthor]}
                  </p>
                  <div className="star-rating mt-16">
                    <Rating
                      name="stars"
                      value={balwyn.map((r) => r.rating)[reviewerID.balAuthor]}
                      size="small"
                      disabled
                      readOnly
                    />
                  </div>
                </div>
                <div className="client-review-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="client-review-item-name text-color-high"></span>
                  <span className="client-review-item-link">
                    <a
                      href={
                        balwyn.map((r) => r.author_url)[reviewerID.balAuthor]
                      }
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {balwyn.map((r) => r.author_name)[reviewerID.balAuthor]}
                    </a>
                  </span>
                  <span className="text-color-low"> / Balwyn</span>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="client-review-item-content">
                  <Image
                    className="profile-img"
                    src={
                      camberwell.map((r) => r.profile_photo_url)[
                        reviewerID.cambAuthor
                      ]
                    }
                    width={"50px"}
                    height={"50px"}
                  />
                  <p className="text-sm mb-0 mt-32">
                    {camberwell.map((r) => r.text)[reviewerID.cambAuthor]}
                  </p>
                  <div className="star-rating mt-16" />
                  <Rating
                    name="stars"
                    value={
                      camberwell.map((r) => r.rating)[reviewerID.cambAuthor]
                    }
                    size="small"
                    disabled
                    readOnly
                  />
                </div>
                <div className="client-review-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="client-review-item-name text-color-high"></span>
                  <span className="client-review-item-link">
                    <a
                      href={
                        camberwell.map((r) => r.author_url)[
                          reviewerID.cambAuthor
                        ]
                      }
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {
                        camberwell.map((r) => r.author_name)[
                          reviewerID.cambAuthor
                        ]
                      }
                    </a>
                  </span>
                  <span className="text-color-low"> / Camberwell</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ClientReview.propTypes = propTypes;
ClientReview.defaultProps = defaultProps;

export default ClientReview;
