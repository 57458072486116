import React from 'react';
import TopHeader from '../components/layout/TopHeader';
import Footer from '../components/layout/Footer';
import MenuHeader from '../components/layout/Header';

const LayoutDefault = ({ children }) => (
  <>
    <TopHeader className="reveal-scale-down" />
    <MenuHeader topDivider bottomDivider navPosition="center" className="reveal-from-bottom" />
    <main className="site-content">
      {children}
    </main>
    <Footer />
  </>
);

export default LayoutDefault;  